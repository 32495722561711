import { StatusFilter, Filter } from '../filters/types';
import { IFilterState } from '../filters/reducer';
import { ICreativity, Status, StatusNames } from '../creativities/types';
import moment from 'moment';

export const statusFilterList: {
  key: StatusFilter;
  label: string;
  value: StatusFilter;
}[] = [
  { key: StatusFilter.ALL, label: 'All', value: StatusFilter.ALL },
  { key: StatusFilter.DRAFT, label: 'Draft', value: StatusFilter.DRAFT },
  ...(process.env.REACT_APP_PENDING_STATE
    ? [
        {
          key: StatusFilter.PENDING,
          label: 'Pending',
          value: StatusFilter.PENDING,
        },
      ]
    : []),
  {
    key: StatusFilter.SCHEDULED,
    label: 'Scheduled',
    value: StatusFilter.SCHEDULED,
  },
  { key: StatusFilter.ONGOING, label: 'On Going', value: StatusFilter.ONGOING },
  {
    key: StatusFilter.COMPLETED,
    label: 'Completed',
    value: StatusFilter.COMPLETED,
  },
];

function hasStarted(startDate?: string) {
  if (!startDate) {
    return false;
  }

  const date = moment(startDate, 'D/M/YYYY');
  const today = moment().startOf('day');
  return date.isSameOrBefore(today);
}

function hasFinished(endDate?: string) {
  if (!endDate) {
    return false;
  }

  const date = moment(endDate, 'D/M/YYYY');
  const today = moment().startOf('day');
  return today.isAfter(date);
}

function deriveCreativeStatus(creative: ICreativity): StatusNames {
  const { startDate, endDate, status } = creative;

  const started = hasStarted(startDate);
  const finished = hasFinished(endDate);

  switch (status) {
    case Status.DRAFT:
      return 'Draft';
    case Status.PENDING_VALIDATION:
      return 'Pending';
    case Status.PUBLISHED:
      if (!started) {
        return 'Scheduled';
      }

      if (!finished) {
        return 'On Going';
      }

      return 'Completed';
    case Status.COMPLETED:
      return 'Completed';
    default:
      return 'Created';
  }
}

export const groupFilterList = [];

export const filtersSelector = (type: Filter) => (state: {
  filters: IFilterState;
}) => {
  const filter = state.filters.filters[type];

  if (!filter) {
    return filterOps.default;
  }

  return filterOps[type][filter]
    ? filterOps[type][filter]()
    : filterOps[type].selection(filter);
};

const filterOps: {
  [key: string]: any;
} = {
  default: () => true,
  [Filter.GROUP]: {
    All: () => () => true,
    selection: (value: string) => (creativity: ICreativity) => {
      return (
        (creativity &&
          creativity.players &&
          creativity.players
            .reduce((groups, player) => [...groups, ...player.groups], [])
            .includes(value)) ||
        creativity.allPlayers
      );
    },
  },
  [Filter.STATUS]: {
    [StatusFilter.ALL]: () => () => true,
    selection: (values: string[]) => (creativity: ICreativity) => {
      return (
        creativity.selected ||
        (values &&
          values.length &&
          (values.includes('All') ||
            values.includes(deriveCreativeStatus(creativity))))
      );
    },
    [StatusFilter.ONGOING]: () => (creativity: ICreativity) =>
      creativity.selected || creativity.status === Status.PUBLISHED,
    [StatusFilter.SCHEDULED]: () => (creativity: ICreativity) =>
      creativity.selected || creativity.status === Status.PUBLISHED,
    [StatusFilter.DRAFT]: () => (creativity: ICreativity) =>
      creativity.selected || creativity.status === Status.DRAFT,
    [StatusFilter.PENDING]: () => (creativity: ICreativity) =>
      creativity.selected || creativity.status === Status.PENDING_VALIDATION,
    [StatusFilter.COMPLETED]: () => (creativity: ICreativity) =>
      creativity.selected || creativity.status === Status.COMPLETED,
  },
};
