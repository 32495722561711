import React, { PureComponent } from 'react';
import { Icon } from 'antd';

import { Text, Wrapper } from './styles';
import { InjectedIntl, injectIntl } from 'react-intl';

interface IProps {
  intl?: InjectedIntl;
}

class PlaybacksTag extends PureComponent<IProps> {
  public render() {
    return (
      <Wrapper>
        <Icon
          type="pushpin"
          rotate={-45}
          theme="filled"
          style={{ color: '#000435', height: '100%' }}
        />
        <Text>Predeterminado</Text>
      </Wrapper>
    );
  }
}

export default injectIntl<any>(PlaybacksTag);
