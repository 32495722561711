import styled from 'styled-components';
import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';

const selectedShadow = (props: { selected: boolean; theme: any }) => {
  if (props.selected) {
    return `box-shadow: 0px 0px 10px ${props.theme.colors.darkBlue};`;
  }
};

interface ExtraCardProps {
  selected?: boolean;
}

export const CustomCard = styled(Card)<CardProps & ExtraCardProps>`
  .ant-card-body {
    padding: 14px;
  }
  .ant-card-actions > li > span {
    min-width: 0;
  }
  .ant-card-body {
    border-top: 1px solid ${props => props.theme.colors.lightGrey};
  }
  ${selectedShadow};
`;

export const TemplateAutor = styled.div`
  color: ${props => props.theme.colors.grey};
  font-size: ${props => props.theme.sizes.M};
  font-weight: ${props => props.theme.fontWeights.regular};
  display: flex;
  margin: 0;
`;

export const Response = styled(TemplateAutor)`
  padding-left: 5px;
  color: ${props => props.theme.colors.grey};
  font-size: ${props => props.theme.sizes.M};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

export const Img = styled.img<any>`
  width: 240px;
  height: auto;
  background-repeat: no-repeat;
`;

export const SelectedWrapper = styled.div`
  display: flex;
  margin: 0;
  flex-direction: column;
  justify-content: space-between;
  height: 65px;
`;

export const CardTitleWrapper = styled.div`
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${props => props.theme.sizes.L};
  color: ${props => props.theme.colors.black};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
  justify-content: space-between;
  height: 65px;
`;
