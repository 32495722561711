import styled from 'styled-components';

export const Text = styled.div`
  padding-left: 5px;
  color: #000435;
  font-size: ${props => props.theme.sizes.S};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid #000435;
  padding: 2px 5px;
  border-radius: 4px;
  width: 150px;
`;
